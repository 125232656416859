<template>
  <div>
    <v-toolbar maxHeight="64px" color="secondary">
      <v-btn text :ripple="false" @click="$router.back()">
        <v-icon left color="primary">mdi-arrow-left</v-icon>
        <span class="primary--text">Volver al resultado de la búsqueda</span>
      </v-btn>
    </v-toolbar>
  
    <v-container grid-list-md text-xs-center>
      <v-row cols="12">
        <v-col xl="4" lg="4" md="6" sm="4" xs="12" style="padding: 40px">
          <v-carousel hide-delimiter-background height="250" :show-arrows="showArrows">
            <v-carousel-item
              v-for="(item,i) in images"
              :key="i"
              :src="item.src"
              contain
            ></v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col xl="4" lg="4" md="6" sm="8" xs="12">
          <v-card :elevation="0">
            <v-card-text>
              <v-card-title class="mySubTitle grey--text font-weight-medium">{{macdata.machinename | uppercase}}</v-card-title>
              <v-card-title class="myTitle secondary--text font-weight-black">{{macdata.machinebrandname | uppercase}}</v-card-title>
              <v-card-title class="myPrice secondary--text font-weight-bold"> {{macdata.machineprice}} € / Día</v-card-title>
              <v-divider></v-divider>
              <div class="secondary--text mt-2 mb-2">{{macdata.machinedescription}}</div>
              <v-divider></v-divider>
              <div class="text-sm-left text-xs-left mt-5">
              <!-- <div>Precio: <span class=" font-weight-bold red--text"> {{macdata.machineprice}} € </span> </div> -->
                <div v-if="macdata.machineminprice"><span class="secondary--text">Precio para alquileres de más de 2 meses: </span> <span class="font-weight-bold secondary--text">{{macdata.machineminprice}} € </span> </div>
                <div v-if="macdata.machinepriceexcess"><span class="secondary--text">Fianza: </span><span  class="font-weight-bold secondary--text">{{macdata.machinepriceexcess}} €</span></div>
                <div v-if="macdata.machinepower"><span class="secondary--text">Potencia: </span><span class="font-weight-bold secondary--text">{{macdata.machinepower}} CV</span></div>
                <div v-if="macdata.machineowneralias"><span class="secondary--text">Propietario: </span><span class="font-weight-bold secondary--text">{{macdata.machineowneralias}}</span></div>
                <div v-if="macdata.machineplace"><span class="secondary--text">Localización: </span><span class="font-weight-bold secondary--text">{{macdata.machineplace}}</span></div>
                <div v-if="macdata.machinedistance"><span class="secondary--text">Distancia: </span><span class="font-weight-bold secondary--text">{{macdata.machinedistance}} Km</span></div>
                <div v-if="macdata.machineyear"><span class="secondary--text">Año de fabricación: </span><span class="font-weight-bold secondary--text">{{macdata.machineyear}}</span></div>
                <div v-if="macdata.machinetacometer !== null && macdata.machinetacometer > 0"><span class="secondary--text">Horas de uso: </span><span class="font-weight-bold secondary--text">{{macdata.machinetacometer}} </span></div>
                  <!-- <li v-for="(ingredient, i) in item.recipe.ingredientLines" :key="i">{{ingredient}}</li> -->
              </div>
              <!-- <v-checkbox  class="text-sm-left text-xs-left custom-checkbox"  label="Transporte disponible" :value="true" :readonly="true" :input-value="hasTransport" ></v-checkbox> 
              <v-checkbox  class="text-sm-left text-xs-left custom-checkbox"  label="Seguro disponible" :value="true"  :readonly="true" :input-value="hasInsurance" ></v-checkbox>  -->
            </v-card-text>
            <v-card-title></v-card-title>
          </v-card>
        </v-col>
        <v-col xl="4" lg="4" style="text-align: center;">
          <v-date-picker no-title
            color="secondary"
            v-model="date1"
            :events="blockeddays"
            :min="today"
            event-color="red lighten-1"
            locale="es-es"
            :first-day-of-week="1"
            header-color="blue"
          ></v-date-picker>
            <v-col style="text-align: center;">
              <v-card :elevation="0" style="padding-left: 80px; padding-right: 80px;">
                <span class="font-weight-medium" color="secondary">
                  Si estás interesado en alquilar este equipo instala la app Android o iOS usando el botón correspondiente
                </span>
              </v-card>
              <v-row xs12 md8>
                <v-col>
                  <a href="https://apps.apple.com/es/app/ploou/id1440338042?mt=8" style="display:inline-block;overflow:hidden;background:url(https://linkmaker.itunes.apple.com/es-mx/badge-lrg.svg?releaseDate=2019-05-17&kind=iossoftware&bubble=ios_apps) no-repeat; width:135px; height:40px;"></a>
                </v-col>
                <v-col style="padding: 0;">
                  <a href='https://play.google.com/store/apps/details?id=com.kuiksoftware.fyfo.Maki2&gl=ES&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Disponible en Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/es_badge_web_generic.png' style="height:60px;"/></a>
                </v-col>
              </v-row>
            </v-col>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "MachineDetail",
  props: ["id"],
  data() {
    return {
      images: [
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg"
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg"
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg"
        },
        {
          src: "https://cdn.vuetifyjs.com/images/carousel/planet.jpg"
        }
      ],
      uuid: "",
      macdata: {},
      machinePhotos: [],
      url: '',
      isLoading2: false,
      hasTransport: false,
      hasInsurance: false,
      attsType1:[],
      attsType2:[],
      attsType3:[],
      attsType4:[],
      caltype: 'month',
      calstart: '2019-07-12',
      calend: '2020-01-06',
      blockeddays:['2019-07-16','2019-07-17'],
      currentMonth: 7,
      date1: undefined,
      showArrows: false,
      today: undefined
    };
  },
  mounted: function() {
    //this.getop(this.$route.query.id);
    this.today = this.getToday()
    if (this.$store.state.currentMachine === undefined) return {};
    else {
      this.images = [];
      this.uuid = this.$store.state.currentMachine.machineuuid;
    }
    this.getop(this.$route.query.id);
  },
  created() {
    this.$store.commit('isResultsVisible', false)
  },
  methods: {
    getop: function(opuuid) {
      const _this = this;
      this.isLoading2 = true;
      const url = `${process.env.VUE_APP_BASE_URI}${'/getmachinebyuuid/'}${opuuid}`;
      this.axios.get(url).then( //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
        function(response) {
          console.log(response);
          this.isLoading2 = false;
          this.macdata = response.data.machinedata;
          if(this.macdata.machineinsuranceavailable == 1) {
            this.hasInsurance = true;
          }
          if(this.macdata.machinetransportavailable == 1) {
            this.hasTransport = true;
          } 
          this.macdata.machineattributes.forEach(element => {
            if(element.attributetype==1){ 
              let attnew = {};
              attnew.label = element.attributename;
              attnew.value = element.attributevaluenum;
              if(element.attributename !== "Potencia")
                this.attsType1.push(attnew);
            } else if(element.attributetype==3) {
              let attnew = {};
              attnew.label = element.attributename;
              attnew.value = element.attributevaluenum;
              this.attsType3.push( attnew);
            } else if( element.attributetype==4){
              let attnew = {};
              attnew.labelx = element.attributename;
              if(element.attributevalueyesno == 0)
                attnew.valuebool = false;
              else
                attnew.valuebool = true;
                _this.attsType4.push( attnew);
            }            
          });

          this.axios.get(`${process.env.VUE_APP_BASE_URI}${'/getphotosformachine/'}${response.data.machinedata.machineuuid}`).then(
            function(response) {
              console.log("" + response);
              //  this.isLoading2 = false;
              this.machinePhotos = response.data;
              for (var a = 0; a < this.machinePhotos.length; a++) {
                var newimg = {};
                newimg.src =`${process.env.VUE_APP_BASE_URI}${'/getimage/'}${this.machinePhotos[a].imageuuid}`
                this.images.push(newimg);
              }
              if(this.images.length > 1) {
                this.showArrows = true
              }
            }.bind(this)
          )
          .catch(
            function(error) {
              console.log(error);
              // this.isLoading2 = false;
            }.bind(this)
          );
        }.bind(this)
      ).catch(
        function(error) {
          console.log(error);
          this.isLoading2 = false;
        }.bind(this)
      );

      const url2 = `${process.env.VUE_APP_BASE_URI}${'/getblockeddaysbyuuid/'}${opuuid}`
      this.axios.get(url2).then( //this.$root.globalsapp.baseurl + "/getoperation/" + opuuid)
        function(response) {
          console.log("" + response);
          //  this.isLoading2 = false;
          this.blockeddays = response.data.blockeddays;
          // for (var a = 0; a < this.machinePhotos.length; a++) {
          //   var newimg = {};
          //   newimg.src =
          //     "https://ploou-produccion.appspot.com/getimage/" +
          //     this.machinePhotos[a].imageuuid;
          //   this.images.push(newimg);
          // }
        }.bind(this)
      ).catch(
        function(error) {
          console.log(error);
          // this.isLoading2 = false;
        }.bind(this)
      );
    },
    getToday() {
      return new Date().toISOString().substr(0, 10)
    }
  }
};
</script>

<style scoped>
.myTitle {
  padding: 0;
  font-size: 1.75rem;
}
.mySubTitle {
  padding-left: 0;
}
.myPrice {
  padding: 0 0 15px 0;
}
</style>